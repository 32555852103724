
import { ref, defineComponent, Ref, watch, onMounted } from 'vue';

export default defineComponent({
  name: "AlertSound",
  setup() {
    const arraySounds: Ref<any> = ref(['Celular', 'E-mail']);
    const sound: Ref<any> = ref(localStorage.getItem('SoundOption') || 'Celular')

    function saveOption(selectedCase: string) {
      switch (selectedCase) {
        case 'Celular':
          localStorage.setItem('Sound', 'sound/bereal.mp3');
          localStorage.setItem('SoundOption', 'Celular')
          break;
        case 'E-mail':
          localStorage.setItem('Sound', 'sound/sound.mp3');
          localStorage.setItem('SoundOption', 'E-mail')
          break;
        default:
          localStorage.setItem('Sound', 'sound/bereal.mp3');
          localStorage.setItem('SoundOption', 'Celular')
          break;
      }
    }

    function goBack() {
      history.back();
    }

    watch(()=> sound.value, ()=> saveOption(sound.value))

    return {
      arraySounds,
      sound, 
      goBack
    }
  },
});
